.flex-center{
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
    max-width: 1200px;
    margin: auto;
}

.flex-li{
    display: flex !important;
    gap: 10px !important;
}